import React, { useEffect, useState } from "react";
import { fetchBlogData } from "../blog-api/BlogApi"; // Adjust the import path if necessary
import MainBlogContainer from "./MainBlogContainer";

const BlogPage = () => {
    const [blogData, setBlogData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const data = await fetchBlogData();
            console.log("Fetched data:", data); // Log fetched data
            setBlogData(data); // Set the fetched data to state
        };
        getData();
    }, []);

    console.log("Current blogData:", blogData); // Log the current state of blogData

    return (
        <div>
            <MainBlogContainer blogData={blogData} /> {/* Pass the blogData as a prop */}
        </div>
    );
};

export default BlogPage;
