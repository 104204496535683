import React, { useEffect } from 'react';
import './FullScreenLoader.css';
import logo from '../../assets/main-logo-white.webp';

const FullScreenLoader = () => {
  useEffect(() => {
    const img = new Image();
    img.src = logo; // Preload the image
  }, []);

  return (
    <div className="fullscreen-loader">
      <div className="loader-container">
        <img src={logo} alt="Loading..." className="loader-logo" />
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
