import React from "react";
import styled from "styled-components";
import jodhpurimage from "./jodhpur-cover.png";
import Mehrangarh from "./mehrangadh.png";
import UmeedBhawan from "./umeed-bhawan.png";
import jaswantthada from "./jaswant-thada.png";
import mandoregardan from "./mandore-gardan-jodhpur.png";
import toorjikajaalra from "./toor-ji-ka-jhaalra.png";
import raojodha from "./rao-jodha.png";
import clocktower from "./clock-tower.png";
import jodhpurvillage from './village-jodhpur.png';
import osian from './osian-mata-temple.png';
import { Helmet } from "react-helmet";

// Styled Components
const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;


const Section = styled.section`
  margin-bottom: 40px;
`;

const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #2d3748;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  color: #4a5568;
  line-height: 1.6;
`;

const Highlight = styled.h3`
  color: #2d3748;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 50%;
  height: auto;
  margin: 20px auto; /* Center the image horizontally */
  display: block; /* Ensure the image is a block element */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  /* Responsive behavior for mobile screens */
  @media (max-width: 768px) {
    width: 70%; /* Set width to 70% for mobile screens */
  }
`;


export default function CarToutsJodhpur() {
  return (
    <div>
    <Helmet>
    <title>Car Tours Jodhpur | Explore Jodhpur's Best Attractions with Taxi Services</title>
    <meta name="description" content="Explore the top attractions of Jodhpur, including Mehrangarh Fort, Umaid Bhawan Palace, Jaswant Thada, and more, with convenient and reliable car tours in Jodhpur. Book your taxi service today for a hassle-free experience." />
    <meta name="keywords" content="Car tours Jodhpur, taxi services in Jodhpur, Mehrangarh Fort, Umaid Bhawan Palace, Jodhpur attractions, Jodhpur sightseeing, Jodhpur taxi, explore Jodhpur, Jodhpur travel, Jodhpur tour" />
  </Helmet>

    <Container>
      <h1 className="text-3xl text-center text-orange-500 my-10 font-bold">CAR TOURS JODHPUR</h1>
      <Paragraph>
        Jodhpur, famously known as the Blue City and the Sun City of India, is a
        must-visit destination for history lovers, culture enthusiasts, and
        explorers. With its blue-washed houses, grand forts, and vibrant
        bazaars, the city offers a unique blend of ancient charm and royal
        heritage. When visiting Jodhpur, exploring its many iconic places
        becomes much easier and more convenient with reliable taxi services in
        Jodhpur. Here’s a guide to the top places you can visit in the city and
        how taxi services make your journey more enjoyable.
      </Paragraph>
      <Image src={jodhpurimage}  alt="Jodhpur Cover" />

      <Section>
        <SubTitle>Mehrangarh Fort</SubTitle>
        <Paragraph>
          One of the largest forts in India, Mehrangarh Fort stands tall over
          the city, offering breathtaking views of the blue-painted houses
          below. Built around 1459 by Rao Jodha, this architectural marvel is a
          must-visit for anyone interested in the history of Jodhpur.
        </Paragraph>
        <Highlight>Why Visit by Car Tours Jodhpur?</Highlight>
        <Paragraph>
          Our taxis are the most convenient way to reach Mehrangarh Fort,
          especially since the fort is located on a hill, and the roads can be
          steep.
        </Paragraph>
        <Image src={Mehrangarh} alt="Mehrangarh Fort" />
      </Section>

      <Section>
        <SubTitle>Umaid Bhawan Palace</SubTitle>
        <Paragraph>
          A symbol of Jodhpur's royal legacy, the Umaid Bhawan Palace is one
          of the world’s largest private residences and is still home to the
          royal family.
        </Paragraph>
        <Highlight>Why Visit by Car Tours Jodhpur?</Highlight>
        <Paragraph>
          Reaching Umaid Bhawan Palace via a car tour ensures a comfortable
          ride to this iconic landmark.
        </Paragraph>
        <Image src={UmeedBhawan} alt="Umaid Bhawan Palace" />
      </Section>

      <Section>
        <SubTitle>Jaswant Thada</SubTitle>
        <Paragraph>
          Just a short distance from Mehrangarh Fort lies Jaswant Thada, an
          elegant marble cenotaph built in memory of Maharaja Jaswant Singh II.
        </Paragraph>
        <Highlight>Why Visit by Car Tours Jodhpur?</Highlight>
        <Paragraph>
          The serene location of Jaswant Thada makes it somewhat challenging
          to reach using public transport.
        </Paragraph>
        <Image src={jaswantthada} alt="Jaswant Thada" />
      </Section>

      <Section>
        <SubTitle>Mandore Gardens</SubTitle>
        <Paragraph>
          For a relaxed day out, head to the beautiful Mandore Gardens, known
          for its collection of royal cenotaphs, temples, and lush gardens.
        </Paragraph>
        <Highlight>Why Visit by Car Tours Jodhpur?</Highlight>
        <Paragraph>
          Mandore Gardens is located about 9 km from the city center, so taking
          a car tour is the best way to travel.
        </Paragraph>
        <Image src={mandoregardan} alt="Mandore Gardens" />
      </Section>

      <Section>
        <SubTitle>Toorji Ka Jhalra (Stepwell)</SubTitle>
        <Paragraph>
          One of Jodhpur’s hidden gems, Toorji Ka Jhalra is an ancient
          stepwell dating back to the 18th century, restored to its original
          beauty.
        </Paragraph>
        <Highlight>Why Visit by Car Tours Jodhpur?</Highlight>
        <Paragraph>
          The narrow streets around Toorji Ka Jhalra can be challenging to
          navigate. Our taxi services ensure you have easy access.
        </Paragraph>
        <Image src={toorjikajaalra} alt="Toorji Ka Jhalra" />
      </Section>

      <Section>
        <SubTitle>Rao Jodha Desert Rock Park</SubTitle>
        <Paragraph>
          Adjacent to Mehrangarh Fort, this park was created to restore the
          natural ecology of a rocky wasteland, showcasing desert flora.
        </Paragraph>
        <Highlight>Why Visit by Car Tours Jodhpur?</Highlight>
        <Paragraph>
          Our taxis easily shuttle you between the park and Mehrangarh Fort.
        </Paragraph>
        <Image src={raojodha} alt="Rao Jodha Desert Rock Park" />
      </Section>

      <Section>
        <SubTitle>Clock Tower and Sardar Market</SubTitle>
        <Paragraph>
          Head to the Clock Tower and Sardar Market for a taste of local
          culture and shopping, from spices to textiles and handicrafts.
        </Paragraph>
        <Highlight>Why Visit by Car Tours Jodhpur?</Highlight>
        <Paragraph>
          Parking near the busy market can be a hassle, but with our taxi
          services, you can avoid the trouble.
        </Paragraph>
        <Image src={clocktower} alt="Clock Tower" />
      </Section>

      <Section>
        <SubTitle>Bishnoi Village Safari</SubTitle>
        <Paragraph>
          For a unique experience beyond Jodhpur’s city limits, embark on a
          Bishnoi Village Safari and explore the traditional way of life.
        </Paragraph>
        <Highlight>Why Visit by Car Tours Jodhpur?</Highlight>
        <Paragraph>
          A day trip to Bishnoi Village requires transportation, and our taxis
          ensure a comfortable ride.
        </Paragraph>
        <Image src={jodhpurvillage} alt="Bishnoi Village" />
      </Section>

      <Section>
        <SubTitle>Osian Temples and Sand Dunes</SubTitle>
        <Paragraph>
          A short drive from Jodhpur, Osian is known for its ancient temples
          and sand dunes, a perfect place for cultural and adventurous
          experiences.
        </Paragraph>
        <Highlight>Why Visit by Car Tours Jodhpur?</Highlight>
        <Paragraph>
          With Osian located 60 km from Jodhpur, our taxis offer the most
          convenient option for this day trip.
        </Paragraph>
        <Image src={osian} alt="Osian Temples" />
        <Paragraph>
          Exploring Jodhpur becomes easier and more convenient when you opt for
          reliable taxi services. Book today for a hassle-free experience!
        </Paragraph>
      </Section>
    </Container>

    </div>
  );
}
