import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import blackcar from '../google-form/1.jpg';

export default function GoogleForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [city, setCity] = useState('');
  const [loading, setLoading] = useState(false); // loading state
  const [isValid, setIsValid] = useState(false); // form validity state
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  useEffect(() => {
    // Check overall form validity
    const noErrors = !errors.name && !errors.email && !errors.phoneNumber && city;
    const allFieldsFilled = name && email && phoneNumber && city;
    setIsValid(noErrors && allFieldsFilled);
  }, [errors, name, email, phoneNumber, city]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValid) {
      toast.error("Please fill in all details correctly.");
      return;
    }

    setLoading(true); // Start loading

    fetch('https://sheetdb.io/api/v1/zebrr91y993m0', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: [
          {
            name: name,
            phone: phoneNumber.toString(),
            email: email,
            city: city
          }
        ]
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.created) {
          toast.success("Form submitted successfully!");
          setName('');
          setEmail('');
          setPhoneNumber('');
          setCity('');
        } else {
          toast.error("Submission failed, please check the data format.");
        }
      })
      .catch((error) => {
        toast.error("Submission failed, please try again.");
        console.error(error);
      })
      .finally(() => setLoading(false)); // End loading
  };



  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrors(prev => ({
      ...prev,
      email: emailRegex.test(value) ? '' : 'Invalid email format'
    }));
    setEmail(value);
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Indian phone numbers
    setErrors(prev => ({
      ...prev,
      phoneNumber: phoneRegex.test(value) ? '' : 'Invalid phone number format'
    }));
    setPhoneNumber(value);
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{
        backgroundImage: `url(${blackcar})`,
      }}
    >
      <ToastContainer />
      <div className="bg-black text-white bg-opacity-90 p-8 rounded-lg shadow-lg max-w-sm w-full text-center">
        <p className="text-4xl font-bold mb-14">Get In Touch !!</p>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
              required
            />
            {errors.name && <small className="text-red-500">{errors.name}</small>}
          </div>
          <div className="mb-4">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => validateEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
              required
            />
            {errors.email && <small className="text-red-500">{errors.email}</small>}
          </div>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => validatePhoneNumber(e.target.value)}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
              required
            />
            {errors.phoneNumber && <small className="text-red-500">{errors.phoneNumber}</small>}
          </div>
          <div className="mb-4">
            <select
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="w-full p-3 border border-gray-300 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="" disabled>Select City</option>
              <option value="Jodhpur">Jodhpur</option>
              <option value="Jaipur">Jaipur</option>
              <option value="Udaipur">Udaipur</option>
              <option value="Kota">Kota</option>
            </select>
          </div>
          <button
            type="submit"
            className="w-full py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition disabled:opacity-50"
            disabled={loading || !isValid} // Disable button if loading or form is invalid
          >
            {loading ? "Submitting..." : "Submit"} {/* Show loading text */}
          </button>
        </form>
      </div>
    </div>
  );
}
