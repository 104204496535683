import "./App.css";
import React, { Suspense, useEffect, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Intro from "./components/introduction/Intro";
import MainNevbar from "./components/navbar/MainNevbar";
import OurPackages from "./components/our-packages/OurPackages";
import SlidingImage from "./components/slider/SlidingImage";
import AboutUs from "./components/about-us/AboutUs.js";
import Footer from "./components/footer/Footer.js";
import LocationOfffer from "./browse-location/LocationOffer.js";
import Partners from "./components/our-partners/Partners.js";
import NotFound from "./components/not-found/NotFound.js";
import NormalParagraph from "./components/small-containers/NormalParagraph.js";
import ContectUs from "./components/contect-us/ContectUs.js";
import Floating from "./components/WhatsApp/Floating.js";
import Reviews from "./components/small-containers/Reviews.js";
import RefundPolicy from "./components/important/RefundPolicy.js";
import PrivacyPolicy from "./components/important/PrivacyPolicy.js";
import CancilationPolicy from "./components/important/CancilationPolicy.js";
import JodhpurBlog from "./components/blogs/jodhpur/JodhpurBlog.js";
import JaisalmerBlog from "./components/blogs/jaisalmer/JaisalmerBlog.js";
import PushkatBlog from "./components/blogs/pushkar/PushkatBlog.js";
import DelhiBlog from "./components/blogs/delhi/DelhiBlog.js";
import ScrollToTop from "./components/ScrollToTop.js";
import TermAndConsition from "./components/important/TermAndConsition.js";
import SocialMedia from "./components/WhatsApp/SocialMedia.js";
import ReactGA from "react-ga4";
import BlogPage from "./components/blog-container/main-container/BlogPage.js";
import FullScreenLoader from "./components/fullscreenloader/FullScreenLoader.js";
import CarsSliding from "./components/car-sliding/CarsSliding.js";
import DiscovertheBestCar from "./components/blog-container/blog-lists/DiscovertheBestCarToursinJodhpur/DiscovertheBestCar.js";
import CarToutsJodhpur from "./components/blog-container/blog-lists/cartoursjodhpur/CarToutsJodhpur.js";
import GoogleForm from "./components/google-form/GoogleForm.js";
// import RajasthanByCar from "./components/blog-container/blog-lists/rajasthanbycar/RajasthanByCar.js"

// Lazy load components
const OneWayTaxi = React.lazy(() =>
  import("./components/one-way-taxi/OneWayTaxi.js")
);
const DetailedInfo = React.lazy(() =>
  import("./components/taxi-services/DetailedInfo.js")
);
const SeightSeenPage = React.lazy(() =>
  import("./components/sightSeensPage/SightSeenPage.js")
);

// list of all blog which are a lazy loaded
const ExploringJodhpurRoadTrip = React.lazy(() =>
  import(
    "./components/blog-container/blog-lists/ExploringJodhpurRoadTrip/ExploringJodhpurRoadTrip.js"
  )
);

const DiscoverReliableCarService = React.lazy(() =>
  import(
    "../src/components/blog-container/blog-lists/dicoverwithreliablecab/DiscoverWithReliableCarService.js"
  )
);
const RajasthanByCar = lazy(() =>
  import(
    "../src/components/blog-container/blog-lists/rajasthanbycar/RajasthanByCar.js"
  )
);
const HiddenGem = React.lazy(() =>
  import("./components/blog-container/blog-lists/hiddengem/HiddenGem.js")
);

export default function App() {
  // Initialize Google Analytics
  useEffect(() => {
    const Trackingid = "G-HM0J366CTJ";
    ReactGA.initialize(Trackingid);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <BrowserRouter>
      <div className="app-container bg-white">
        {" "}
        {/* Flex container */}
        <MainNevbar />
        <ScrollToTop />
        <div className="content-wrapper">
          {" "}
          {/* Flex content */}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SlidingImage />
                  <Intro />
                  <SocialMedia />
                  <OurPackages />
                  <CarsSliding />
                  <LocationOfffer />
                  <NormalParagraph />
                  <Reviews />
                  <GoogleForm/>
                  <Partners />
                </>
              }
            />
            <Route
              path="/onewaytaxi/:cityName"
              element={
                <Suspense
                  fallback={
                    <div className="text-3xl font-bold text-center">
                      <FullScreenLoader />
                    </div>
                  }
                >
                  <SeightSeenPage />
                </Suspense>
              }
            />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contect-us" element={<ContectUs />} />

            <Route
              path="/taxi-services/:LocationName"
              element={
                <Suspense fallback={<FullScreenLoader />}>
                  <DetailedInfo />
                </Suspense>
              }
            />
            <Route
              path="/one-way-taxi"
              element={
                <Suspense fallback={<FullScreenLoader />}>
                  <OneWayTaxi />
                </Suspense>
              }
            />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/priviacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/cancellation-policy"
              element={<CancilationPolicy />}
            />
            <Route path="/term-and-conditions" element={<TermAndConsition />} />
            <Route path="/car-rent-jodhpur" element={<JodhpurBlog />} />
            <Route path="/car-rent-jaisalmer" element={<JaisalmerBlog />} />
            <Route path="/car-rent-pushkar" element={<PushkatBlog />} />
            <Route path="/car-rent-delhi" element={<DelhiBlog />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/blogs" element={<BlogPage />} />

            {/* list of all blogs */}
            <Route
              path="/exploring-Jodhpur-road-trip-taxi-in-jodhpur"
              element={
                <Suspense fallback={<FullScreenLoader />}>
                  <ExploringJodhpurRoadTrip />
                </Suspense>
              }
            />
            <Route
              path="/discover-the-Best-Car-Tours-in-Jodhpur"
              element={
                <Suspense fallback={<FullScreenLoader />}>
                  <DiscovertheBestCar />
                </Suspense>
              }
            />

            <Route
              path="/car-tours-jodhpur-blogs"
              element={
                <Suspense fallback={<FullScreenLoader />}>
                  <CarToutsJodhpur />
                </Suspense>
              }
            />
            <Route
              path="/exploring-Jodhpur-with-Reliable-Taxi-Services"
              element={
                <Suspense fallback={<FullScreenLoader />}>
                  <DiscoverReliableCarService />{" "}
                </Suspense>
              }
            />
            <Route
              path="/exploring-rajasthan-by-car-A-Royal-Road-Trip-Adventure"
              element={
                <Suspense fallback={<FullScreenLoader />}>
                  <RajasthanByCar />
                </Suspense>
              }
            />

            <Route
              path="/Hidden-Gems-of-Rajasthan-You-Can-Explore-with-a-Car-rental-jodhpur"
              element={
                <Suspense fallback={<FullScreenLoader />}>
                  <HiddenGem />
                </Suspense>
              }
            />

            {/* describe before routes */}
          </Routes>
        </div>
        <Footer /> {/* its footer , Stays at the bottom */}
        <Floating />
      </div>
    </BrowserRouter>
  );
}
