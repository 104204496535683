export default function OurPackages() {
  return (
    <div>
      <p className="text-center font-black text-4xl m-8 animate-bounce">
        Our <span className="text-orange-400">Packages</span>
      </p>
      
    </div>
  );
}
