import imgswentyfourentuseven from "../../assets/full-hours-support.svg";
export default function NormalParagraph() {
  return (
    <div>
      <div className="p-10 md:p-5 sm:p-2">
        <p className="text-center text-4xl m-10 sm:m-5">WHY CHOOSE US</p>

        {/* Grid with equal height columns */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 text-lg m-10 items-start min-h-full">
          {/* Left column */}
          <div className="flex flex-col justify-between">
            <ul className="list-disc list-inside space-y-3">
              <li>
                Our customers always return due to the satisfaction and
                contentment they receive from our services.
              </li>
              <li>
                We have a transparent pricing policy, ensuring that customers
                pay a fair and justified price.
              </li>
            </ul>
          </div>

          {/* Right column */}
          <div className="flex flex-col justify-between">
            <ul className="list-disc list-inside space-y-3">
              <li>
                Competitive rates and professional service are at the core of
                our offerings.
              </li>
              <li>
                With our extensive network of cabs and drivers, we can give you
                the service and attention you deserve.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center text-orange-500 bg-black w-full p-8 text-2xl">
        <p className="flex items-center">
          We are available{" "}
          <img
            src={imgswentyfourentuseven} 
            alt="okay"
            className="text-red-300 w-20 h-20 p-4"
          />
        </p>
      </div>
    </div>
  );
}
